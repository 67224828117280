.gdpr-popup {
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1050;
  background: rgba(38, 38, 38, 0.85);
  backdrop-filter: blur(5px);
  transition: bottom 0.5s ease-in-out;
  display: none;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.show-consent {
  display: flex;
  bottom: 0;
}

// Inner Content Container
.gdpr-content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1699px) {
    width: 90%;
  }

  @media screen and (max-width: 1198px) and (min-width: 670px) {
    flex-direction: column;
  }

}

.gdpr-row {
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.gdpr-title {
  color: #F7f7F7;
  white-space: nowrap;
}

.gdpr-text {
  p {
    color: #F7f7F7;
    font-size: 16px;
  }
}

.gdpr-inner-text {
  padding-right: 0;
  @media (max-width: 670px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  @media (max-width: 638px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    padding-left: 0;
  }

}

// Toggle Row
// .gdpr-toggle-row {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   gap: 15px;

//   .custom-control {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//   }
// }

.show-toggles {
  display: flex;
}

.gdpr-toggle-row {
  display: none;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 46px;
  margin-top: 10px;

  .custom-control {
      padding-left: 1rem;
  }

  @media (max-width: 1699px) {
    margin-left: 34px;
  }

  @media (max-width: 1342px) {
      flex-wrap: wrap;
      justify-content: space-between;

      .custom-control {
          margin-right: 0;
      }
  }

  @media (max-width: 672px) {

    margin-left: 0;

    .custom-control {
        flex-direction: column;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .custom-control-input {
      position: relative;
    }

    .toggle-row {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      margin-bottom: .5rem !important;
    }

    .toggle-col {
      position: relative;
      width: 100%;
      flex: 0 0 50%;
      padding-right: 15px;
      max-width: 50%;
    }

    .custom-switch .custom-control-label::before {
      left: 20px;
    }

    .custom-switch .custom-control-label::after {
      left: 22px;
    }
  }

  @media (max-width: 638px) {
    .custom-control {
        flex: 0 0 100%;
    }
  }
}

.toggle-row {
  display: contents;
}

.custom-switch .custom-control-label {
  font-size: 16px;
  color: #F7f7F7;
  position: static;
}

.custom-switch .custom-control-label::before {
  width: 35px;
  height: 20px;
  border-radius: 25px;
  background: none;
  border: 1px solid #F7f7F7;
  box-shadow: none !important;
}

.custom-switch .custom-control-label::after {
  top: 4px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  background-color: #F7f7F7;
}

.custom-control-input:disabled~.custom-control-label {
  color: #F7f7F7;
}

.custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(1.25rem);
  transform: translateX(1.25rem);
  background-color: #F7f7F7;
}

.custom-control-input:checked~.custom-control-label::before {
  border: none;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
  background-color: #FFC107;
  cursor: pointer;
}

#toggleNecessary:checked~.custom-control-label::before {
  background-color: #FFC107;
}

// GDPR Buttons
.gdpr-bottom-btns {
  font-size: 14px;
  margin-right: 10px;
  width: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 670px) {
    width: 100%;
  }

  @media screen and (max-width: 416px) {
    width: 110%;
  }

}

.gdpr-btn-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 50%;

  @media screen and (max-width: 1699px) {
    width: 100%;
    margin-top: 10px;
  }

  @media screen and (max-width: 668px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  @media (max-width: 638px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#allow_selection_cookies, #reject_all_cookies {
  background: none;
}

#reject_all_cookies {
  border: 2px solid #f7f7f7;
}

#allow_selection_cookies {
  color: #f7f7f7;
  border: 2px solid #eabc35;
}

#allow_all_cookies {
  background-color: #eabc35;
}
