.news-article-header {
    .hero-container {
        align-items: center;
    }
    .hero-container.pad-vert-120 {
      padding-top: 40px;
      padding-bottom: 0;
        @media screen and (min-width: 992px) {
            padding-top: 80px;
        }

    }
}

@media screen and (max-width: 991px) {
    .news-article-arc.mar-top-50 {
        margin-top: 0;
    }
}
