.video-thumbnail {
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.video-container {
    position: relative;
}

.videos-height-1 {
    @media screen and (max-width: 575px) {
        height: 220px;
    }
    @media screen and (min-width: 430px) and (max-width: 767px) {
        height: 280px;
    }
}

.videos-height-2 {
    height: 220px;
    @media screen and (min-width: 430px) and (max-width: 767px) {
        height: 280px;
    }
    @media screen and (min-width: 1199px) {
        height: 340px;
    }
}

.videos-height-3 {
    @media screen and (min-width: 430px) and (max-width: 767px) {
        height: 280px;
    }
     height: 220px ;
}

.play-icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.video-iframe-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.video-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
