.main-header-content {
    svg {
        max-width: 100%;
    }

    .row {
        max-width: 900px;
        margin: 0 auto;
    }

    .right-col {
        display: flex;

        img {
            max-width: 90%;
            padding-bottom: 125px;
            z-index: 3;
        }

        .award-badge {
            position: absolute;
            bottom: 0;
            background-color: white;
            border-radius: 5px 5px 0 0;
            padding: 25px 50px;
            text-align: center;
            z-index: 3;

            p {
                margin: 5px;
                padding: 0;
                line-height: initial;

                strong {
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            img {
                position: absolute;
                width: 60px;
                margin-left: -27px;
                margin-top: -46px;
                left: 0;
            }
        }
    }

    .left-col {
        color: white;
        flex-direction: column;
        display: flex;
        justify-content: center;
        z-index: 2;
    }

    .wrapper-btn {
        .link-nav {
            text-decoration: none;
            text-transform: uppercase;
            border-radius: 0;
            padding: 10px;
            font-size: 9pt;
            font-weight: bold;
        }

        .btn-regular {
            background-color: white;
            color: #3e90d6;
            border: 2px solid white;
        }

        .btn-transparent {
            background-color: transparent;
            color: white;
            border: 2px solid white;
        }
    }
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .header-graphic-element {
        display: none;
    }

    .main-header-content {
        .right-col {
            .award-badge {
                position: initial;
                margin: 45px auto 0;

                img {
                    width: 50px;
                    position: initial;
                    margin-left: 0;
                    margin-top: -60px;
                    padding-bottom: 0;
                }
            }
        }

        .left-col {
            justify-content: center;
            padding: 50px 20px;
            align-items: center;
        }
    }
}

.general-bg-xxx {
    svg {
        width: 100%;
    }

    width: 900px;
    position: absolute;
    top: -425px;
    z-index: 1;
    opacity: 0.2;
}

.general-bg-xxx2 {
    @extend .general-bg-xxx;
    top: -425px;
    z-index: -1;
    opacity: 1;
}

@media (max-width: 991.98px) {
    .general-bg-xxx,
    .general-bg-xxx2 {
        display: none;
    }
}

.header-message {
    width: 100%;
    min-height: 80px;
    @media screen and (max-width: 767px) {
        font-size: 14px;
    }

    p {
        margin-bottom: initial;
        padding-bottom: initial;
    }
}

.header-video {
    height: 62vh;
    @media screen and (min-width: 992px) {
        // height: 72vh;
    }
    .pad-vert-120 {
        padding-bottom: 0;
    }

}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
}

.gradient-bg {
    background: linear-gradient(90deg, rgba(255,255,255,1) 33%, rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -9;
}

.glassdoor {
    position: relative;
    box-shadow: 0 4px 11px 0 #d9dddd;
    top: 6vh;
    @media screen and (min-width: 576px) {
        border-radius: 35px;

    }
}

.glassdoor-border {
    @media screen and (min-width: 576px) and (max-width: 991px) {
        border-right: 1px solid #9b9b9b;
    }
    @media screen and (min-width: 992px) {
        border-left: 1px solid #9b9b9b;
        border-right: 1px solid #9b9b9b;
    }
}

.header-video .hero-container {
    @media screen and (min-width: 576px) {
       // padding-top: 150px;
    }
    @media screen and (min-width: 1400px) {
        padding-top: 200px;
    }
}

.two-badges {
    max-height: 250px;
    max-width: 200px;
}

.left-badge {
    @media screen and (max-width: 991px) {
        width: 80%;
    }
}

.right-badge {
    @media screen and (max-width: 991px) {
        width: 100%;
    }
}
