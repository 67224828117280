.testimonial.promo-news {
    padding: 80px 5% 80px 2%;
    margin-left: 0;
    margin-right: 0;
    @media screen and (max-width: 576px) {
        padding: 0 0 40px 0;
    }
    @media screen and (min-width: 992px) {
        margin-bottom: 150px;
    }

    .promo-news-text-container {
        @media screen and (max-width: 576px) {
            padding-right: 5%;
            padding-left: initial;
        }
    }
    &.mar-neg-top-400 {
        @media screen and (min-width: 575px)  and (max-width: 992px) {
            margin-top: -320px;
        }
    }
}

.cards-container-news.cards-container {
    @media screen and (min-width: 0px) and (max-width: 576px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: initial;
    }
}

@media screen and (min-width: 992px) {
    .arc-bg-container.mar-top-360 {
        margin-top: 300px;
    }
}

.news-service-img-container {
    border-radius: 35px;
    height: 474px;
    margin-bottom: -180px;
    margin-left: 20px;

    @media screen and (max-width: 576px) {
        border-radius: initial;
        height: initial;
        margin-left: initial;
        margin-bottom: 50px;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: 424px;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        margin-top: -180px;
        margin-left: 20px;
        margin-bottom: 70px;
        border: 3px solid #f5eccf;
    }
}

.news-category-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.news-category-tags {
    &.news-category-all a {
        box-shadow: initial;
    }

    a {
        border-radius: 20px;
        box-shadow: 0 5px 10px 3px #e3e3e3;
        padding: 6px 20px;
        display: block;

        &:hover {
            box-shadow: 0 5px 10px 3px #c5c5c5;
        }

        &.active {
            box-shadow: initial;
            background: #242424;
            color: #ffffff;
            opacity: 100%;
        }
    }
}

.news-category-all {
    @media screen and (min-width: 992px) {
        order: 2;
        margin-left: auto;
    }

    a.active {
        box-shadow: initial;
        background: #eabc35;
        color: #232323;
    }

    a:hover {
        box-shadow: initial;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;

    ul li {
        display: block;
        border-radius: 100%;
    }
}

.page-number {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    text-align: center;
    padding-top: 3px;

    &.active {
        background: #e75b54;
        color: #ffffff;
    }

    &.active:hover {
        opacity: 100%;
        background: #e75b54;
    }

    &:hover {
        background: #f7f7f7;
        opacity: 100%;
    }
}
.featured-news-img {
    width: 100%;

    @media screen and (min-width: 576px) {
        border-radius: 35px;
    }
}

.news-header {
    .hero-container {
        align-items: center;
    }
    .hero-container.pad-vert-120 {
        padding-top: 40px;
        padding-bottom: 0;
        @media screen and (min-width: 992px) {
            padding-top: 80px;
        }

    }
}

.news-arc.mar-top-400 {
    margin-top: 320px;
    @media screen and (max-width: 575px) {
        margin-top: 360px;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        margin-top: 400px;
    }
}

.promo-news-text-container {
    @media screen and (max-width: 575px) {
        h3 {
            line-height: 28px;
        }
        p {
            line-height: 24px;
        }
    }
}
