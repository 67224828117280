.cards-container {
    display: flex;
    gap: 33px;
    flex-wrap: wrap;
    @media screen and (min-width: 0px) and (max-width: 592px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0 5%;
    }
}

.cards {
    flex: 2 0 21%;
    max-width: 23%;
    min-width: 250px;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 3px 4px 15px 0 #efefef;
    cursor: pointer;
    @media screen and (min-width: 0px) and (max-width: 592px) {
        max-width: initial;
        min-width: initial;
    }
    @media screen and (min-width: 593px) and (max-width: 906px) {
        max-width: 48%;
    }
    @media screen and (min-width: 907px) and (max-width: 1221px) {
        max-width: 31%;
    }

    img {
        width: 53px;
        height: 53px;
        object-fit: cover;
    }
}

// Three-cards-in-a-row block styling starts here

.cards-with-bg {
    .cards-no-bg-container  {
        @media screen and (min-width: 576px) {
            margin-right: initial;
            margin-left: initial;
        }
        @media screen and (min-width: 1700px) {
            margin-right: initial;
            margin-left: initial;
        }
    }
    .three-cards-container {
        padding-bottom: 4rem;
        @media screen and (min-width: 576px) {
            margin-bottom: 2.5rem;
        }

    }
    .cards-container {
        padding-bottom: 4rem;
        @media screen and (min-width: 576px) {
            margin-bottom: 2.5rem;
        }
    }
    .cards , .three-cards-item {
        box-shadow: initial;
        cursor: pointer;
    }
}

.three-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 85px;
    @media screen and (min-width: 0px) and (max-width: 575px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0 5%;
    }

    &.gap-35-62 {
        gap: 35px 62px;
    }

}

.cards-no-bg-container  {
    @media screen and (min-width: 576px) {
        margin: 0 5%;
    }
    @media screen and (min-width: 1700px) {
        margin: 0 18%;
    }
}

.three-cards-item {
    flex: 2 0 25%;
    max-width: 30%;
    min-width: 300px;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 5px 5px 30px #efefef;
    cursor: pointer;

    img {
        width: 53px;
        height: 53px;
        object-fit: cover;
        @media screen and (max-width: 576px) {
            width: 45px;
            height: 45px;
        }
    }

    @media screen and (min-width: 0px) and (max-width: 329px) {
        min-width: initial;
        padding: 15px;
    }
    @media screen and (min-width: 0px) and (max-width: 761px) {
        max-width: initial;
    }
    @media screen and (min-width: 762px) and (max-width: 1137px) {
        max-width: 47%;
    }
    @media screen and (min-width: 1138px) and (max-width: 1300px) {
        max-width: 29.8%;
    }
    @media screen and (min-width: 1300px) and (max-width: 1399px) {
        max-width: 30.1%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1499px) {
        max-width: 30.3%;
    }
    @media screen and (min-width: 1500px) and (max-width: 1599px) {
        max-width: 30.45%;
    }
    @media screen and (min-width: 1600px) and (max-width: 1699px) {
        max-width: 30.6%;
    }
}

.card-shadow:hover {
    box-shadow: 0 0 20px 0 #a0a0a0;
}
