.arc-bg-container {
    overflow: hidden;
}

.arc-bg {
    border-top-left-radius: 200%;
    border-top-right-radius: 200%;
    height: 80px;
    width: 200%;
    bottom: -2px;
    position: relative;
    right: 50px;
    z-index: -1;
    @media screen and (max-width: 575px) {
        height: 30px;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        height: 40px;
    }
}
