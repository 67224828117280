$margin-top-desktop: 4%;
$margin-left-large-desktop: 10%;
$margin-left-desktop: 5%;
$margin-top-mobile: 15%;

#search-container {
  margin-left: auto;
  flex-wrap: nowrap;
  position: relative;

  .input-group {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .input-group-append {
    position: absolute;
    left: 8px;
  }
}

.search-inner-box {
  margin: 0 20px;
  width: min-content;
}

.search-row-center {
  align-items: center;
  justify-content: flex-end;
}

.search-form {
  width: 100%;
  margin: 0 auto;

  .search-input {
    position: absolute;
    width: 0;
    right: 0;
    height: 50px;
    font-family: "Metropolis-Regular", sans-serif;
    border: 1px solid #ccc;
    border-radius: 50px;
    padding-right: 40px;
    padding-left: 15px;
    transition: width 0.5s ease, border-color 0.5s ease;
    z-index: 2;

    &:focus {
      border-color: #eabc35;
      box-shadow: none;
      outline: none;
    }
  }

  .search-btn {
    right: 46px;
    z-index: 3;
  }
}

#search-container:hover .search-input {
  width: 500px;
  z-index: 4;
}

#search-container:hover .search-btn {
  z-index: 5;
}

@media only screen and (max-width: 1199px) {

  .search-inner-box {
    display: flex;
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  .input-group-append {
    display: flex;
  }

  #search-container {
    margin-left: 0;
  }

  #search-container:hover .search-input {
    width: 250px;
  }

  .search-inner-box {
    margin: 0;
  }

  .search-form .search-input {
    position: relative;
    width: 250px;
    right: 0;
    flex: 0;
  }

  .search-form .search-btn {
    background: 0 0;
    position: absolute;
    left: 200px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    font-size: 1.2rem;
  }

  .search-row-center {
    justify-content: center;
  }

  .demo-container {
    justify-content: flex-start;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }

  .demo-container, .navbar-lang-selection {
    width: 100%;
  }

  .navbar-lang-selection .nav-item .nav-link {
    padding-top: 0 !important;
  }
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.card {
  background-color: #fff;
  width: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.search-result-cards {
  .card-title {
    font-size: 1.25rem;
    margin: 0;
  }
}
