.contact-hero-container {
    margin-top: 80px;

    &.row {
        @media screen and (max-width: 575px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media screen and (min-width: 576px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    p {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
        line-height: 40px;
    }

    h1 {
        @media screen and (max-width: 1199px) {
            font-size: 2rem;
        }
    }

    @media screen and (min-width: 1200px) {
        margin-top: 240px;
    }
}

.contact-hero-text-container {
    @media screen and (max-width: 575px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.contact-form-container {
    padding: 50px;
    max-width: 552px;

    @media screen and (max-width: 575px) {
        padding: 40px;
    }

    @media screen and (min-width: 576px) {
        border-radius: 30px;
    }

    @media screen and (max-width: 1199px) {
        max-width: initial;
    }

    @media screen and (min-width: 1200px) {
        margin-top: -50px;
    }
}

.contact-text-area {
    border: none;
    border-radius: 22px;
    width: 100%;
    padding: 32px;
}

.contact-cards-container {
    @media screen and (min-width: 1200px) and (max-width: 1724px) {
        flex-wrap: initial;
        flex-direction: column;
        .col-xl-6 {
            max-width: initial;
            margin-bottom: 1rem;
        }
    }
}

.contact-cards {
    border-radius: 30px;
    @media screen and (min-width: 1200px) {
        width: 95%;
    }
}

.contact-single-title {
    @media screen and (max-width: 575px) {
        padding-top: 25px;
    }
}

.google-maps {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.left-border {
    @media screen and (min-width: 992px) {
        border-right: 2px solid #dedede;
    }
}

.right-border {
    @media screen and (min-width: 992px) {
        border-left: 2px solid #dedede;
    }
}
