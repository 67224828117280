.big-button {
    border: none;
    padding: 16px 44px;
    border-radius: 60px;
    font-size: 24px;
    @media screen and (max-width: 991px) {
        padding: 12px 24px;
        font-size: 20px;
        margin-bottom: 80px;
    }
    @media screen and (min-width: 992px) and (max-width: 1201px) {
        margin-bottom: 120px;
    }
    &.btn-contact-us {
        @media screen and (max-width: 575px) {
            font-size: 14px;
            width: 100%;
        }
    }
}

.small-button {
    border: none;
    padding: 8px 24px;
    border-radius: 60px;
    font-size: 18px;
}

.big-button:hover {
    opacity: 75%;
}

.btn.focus, .btn:focus {
    outline: initial;
    box-shadow: initial;
}

button {
    outline: none !important;
}
