.logos {
    img {
        max-width: 180px;
    }
    @media screen and (max-width: 575px) {
        padding: 10% 5%;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        padding: 5%;
    }
}

.plan-demo-container {
    padding: 80px 100px;
    position: relative;
    @media screen and (min-width: 576px) {
        border-radius: 45px;
        //margin-bottom: 60px;
    }
    @media screen and (max-width: 767px) {
        padding: 50px 30px;
    }

    @media screen and (min-width: 1200px) {
        //margin-bottom: 160px;
    }
}

.demo-form-container {
    border-radius: 40px;
    padding: 50px;
    left: 46%;
    top: 23%;
    max-width: 552px;
    @media screen and (max-width: 575px) {
        padding: 6px 24px 24px 24px;
    }
    @media screen and (max-width: 1199px) {
        max-width: initial;
        left: 0;
    }
    @media screen and (min-width: 1200px) {
        position: absolute;
        box-shadow: 0 5px 8px 0 #efefef;
    }
}

.input-fields {
    border: none;
    border-radius: 60px;
    height: 64px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    @media screen and (max-width: 575px) {
        height: 50px;
    }
}

.social-icon {
    width: 55px;
    @media screen and (min-width: 576px) {
        width: 45px;
    }
    @media screen and (max-width: 575px) {
        margin-top: 45px;
    }
}

.logos-container {
    padding-left: 10%;
    padding-right: 10%;
    @media screen and (min-width: 0px) and (max-width: 991px) {
        padding-left: 48px;
        padding-right: 48px;
    }
    @media screen and (min-width: 1700px) {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.rating-container {
    @media screen and (min-width: 992px) {
        margin-top: 60px;
    }
}
