.description {
    @media screen and (min-width: 576px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    p {
        margin-bottom: 0;
    }
}
