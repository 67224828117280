.toc-section {
    margin-bottom: 80px;
}

.toc-list {
    list-style-type: decimal;
    padding-left: 1.5rem;
    margin: 0;
}

.toc-item {
    padding-left: 10px;
    margin-bottom: 8px;
}

.toc-link {
    text-decoration: none;
    font-size: 20px;
    display: block;
}

.article-metadata {
    margin-bottom: 80px;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 4px solid #eabc35;
}

.article-metadata p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
    margin-right: 20px;
}

.article-metadata strong {
    color: #333;
}

.rating-form p {
    font-weight: bold;
    margin: 0;
}

#total-ratings {
    padding-right: 5px;
}

#average-rating-widget {
    padding: 2px 5px;
}

#submit-rating {
    line-height: normal;
    margin-top: 10px;
}
