.btn-info {
    background-color: #3e90d6;
    border-radius: 0;
    font-size: 9pt;
}

li.nav-item {
    a {
        text-decoration: none;
        color: #424242;
        padding: 10px;
    }
}

.lang {
    text-decoration: none;
    font-size: 10pt;
    color: #424242;

    &:hover {
        text-decoration: none;
        color: #424242;
    }

    border-left: 1px solid black;
    padding: 9px;
}

#menu-toggle {
    width: 100px;
    height: 100px;
    margin: 50px auto;
    position: relative;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;

    &:hover {
        background: rgba(255, 255, 255, 0.8);
    }

    #hamburger {
        position: absolute;
        height: 100%;
        width: 100%;

        span {
            width: 60px;
            height: 4px;
            position: relative;
            top: 24px;
            left: 20px;
            margin: 10px 0;

            &:nth-child(1) {
                transition-delay: 0.5s;
            }

            &:nth-child(2) {
                transition-delay: 0.625s;
            }

            &:nth-child(3) {
                transition-delay: 0.75s;
            }
        }
    }

    #cross {
        position: absolute;
        height: 100%;
        width: 100%;
        transform: rotate(45deg);

        span {
            &:nth-child(1) {
                height: 0%;
                width: 4px;
                position: absolute;
                top: 10%;
                left: 48px;
                transition-delay: 0s;
            }

            &:nth-child(2) {
                width: 0%;
                height: 4px;
                position: absolute;
                left: 10%;
                top: 48px;
                transition-delay: 0.25s;
            }
        }
    }
}

#menu-toggle.open {
    #hamburger {
        span {
            width: 0%;

            &:nth-child(1) {
                transition-delay: 0s;
            }

            &:nth-child(2) {
                transition-delay: 0.125s;
            }

            &:nth-child(3) {
                transition-delay: 0.25s;
            }
        }
    }

    #cross {
        span {
            &:nth-child(1) {
                height: 80%;
                transition-delay: 0.625s;
            }
        }

        span {
            &:nth-child(2) {
                width: 80%;
                transition-delay: 0.375s;
            }
        }
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: end;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        justify-content: end;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

@media (max-width: 991.98px) {
    .lang {
        width: 100%;
        display: block;
        margin-bottom: 15px;
    }
    .navbar-collapse {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.navbar-light .navbar-toggler {
    border: 0;
    padding: 5px;

    &:focus {
        border: 0 !important;
        outline: none !important;
    }

    .fa-times-circle {
        position: absolute;
        right: 22px;
        font-size: 20pt;
        top: 27px;
        display: none;
    }
}

.navbar-container {
    max-width: 80%;
    margin: 0 auto;
    @media screen and (max-width: 1699px) {
        max-width: 90%;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    color: initial;
    background-color: #f7f7f7;
}

.dropdown-item:hover {
    background-color: #f7f7f7;
}

.navbar-light .navbar-nav .nav-link {
    color: #232323;

    &:hover {
        opacity: 75%;
    }
}

@media (max-width: 1199px) {
    .navbar-nav .dropdown-menu {
        border: none;
    }
}

.navbar {
    align-items: initial;
}

.navbar-brand {
    padding-top: 0.1rem;
}

@media (min-width: 1200px) {
    .dropdown-menu-left.dropdown-menu::before {
        left: 30%;
    }
    .dropdown-menu-left.dropdown-menu::after {
        left: 30%;
    }
    .dropdown-menu-left.dropdown-menu-center {
        -webkit-transform: translate(-30%, 0);
        -o-transform: translate(-30%, 0);
        transform: translate(-30%, 0);
    }
    .dropdown-menu::before {
        border-bottom: 24px solid rgba(0, 0, 0, 0.2);
        border-left: 24px solid rgba(0, 0, 0, 0);
        border-right: 24px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -100%);
    }
    .dropdown-menu::after {
        border-bottom: 23px solid #FFFFFF;
        border-left: 24px solid rgba(0, 0, 0, 0);
        border-right: 24px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 50%;
        position: absolute;
        top: 1px;
        transform: translate(-50%, -100%);
    }

    .navbar-nav .dropdown-menu {
        margin-top: 24px;
    }
    .dropdown:hover .dropdown-menu,
    .dropdown .dropdown-menu, {
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
    }
    .dropdown-menu-center {
        right: auto;
        left: 50%;
        -webkit-transform: translate(-52%, 0);
        -o-transform: translate(-52%, 0);
        transform: translate(-52%, 0);
    }
    li.nav-item a.dropdown-item {
        padding: 0;
        font-size: 15px;
    }
    .w-xl-700px {
        width: 1050px;
    }
    .max-w-100.w-xl-700px {
        max-width: initial;
    }
    .width-100.dropdown-menu-center {
        width: initial;
    }
    .row .pad-r-0 {
        padding-right: 1rem;
    }
}

li.nav-item a.dropdown-long-link {
    color: #70b4a2;
}

li.nav-item a.dropdown-item {
    white-space: normal;
    @media screen and (max-width: 575px) {
        font-size: 16px;
    }

}

.toggle-icons {
    display: none;
    &.show-icon {
        display: inline-block;
    }
}

.eclipse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 34px;
    background-color: #fff;
    border-radius: inherit;
}

.demo-container {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    margin: 0 30px;

    @media screen and (max-width: 1346px) {
        margin: 0 10px;
    }

    @media screen and (max-width: 1200px) {
        margin: 2px;
    }

    .demo-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .small-button {
        padding-inline: 8px;
    }

    .demo-btn-title {
        margin: 0 10px;
        color: #fff;
        font-family: 'Metropolis-SemiBold', sans-serif;
        font-weight: 700;
    }
}



@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .justify-content-xl-end.justify-center-1200-1400 {
        justify-content: center !important;
    }
    .small-button {
        padding: 8px 18px;
        font-size: 16px;
    }
}

.h-80vh {
    height: 80vh;
}





.col-wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.col-wrap>* {
    flex: 0 1 0px;
}

.dropdown-body-container {
    max-height: 75vh;
    overflow-y: auto;
}
