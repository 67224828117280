.accordion-container {
    display: flex;
    @media screen and (max-width: 575px) {
        //margin-bottom: 2.5rem;
    }
    @media screen and (min-width: 576px) {
        border-radius: 35px;
    }

    &.accordion-columns-1 {
        flex-direction: column;
    }

    &.accordion-columns-2 {
        gap: 2%;
        flex-wrap: wrap;
        align-items: start;
        @media screen and (max-width: 991px) {
            gap: initial;
        }
    }
}

.accordion-item {
    border-radius: 20px;
    box-shadow: 0 0 0 2px #f7f7f7;
    @media screen and (max-width: 575px) {
        //border-radius: initial;
        margin-right: 15px;
        margin-left: 15px;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .accordion-icon-plus {
        display: block;
    }

    .accordion-icon-minus {
        display: none;
    }

    &.accordion-items-2 {
        width: 49%;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

.accordion-item.active {
    .accordion-icon-plus {
        display: none;
    }

    .accordion-icon-minus {
        display: block;
    }
}

.accordion-question {
    font-size: 22px;
    margin-bottom: initial;
    line-height: 30px;
    cursor: pointer;
    @media screen and (max-width: 575px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.accordion-answer {
    font-size: 18px;
    line-height: 30px;
    margin-top: 20px;
    display: none;
    color: #232323;
    p {
        padding-bottom: 0;
    }
    @media screen and (max-width: 575px) {
        p {
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.accordion-icon-container {
    display: flex;
    align-items: center;
}

.accordion-icons {
    width: 20px;
    cursor: pointer;
}
