.colored-box-rounded {
    .colored-box {
        padding: 60px 7%;
        @media screen and (max-width: 575px) {
            padding: 40px 5%;
        }
        @media screen and (min-width: 576px) {
            border-radius: 35px;
        }
    }
}

.colored-box {
    padding: 60px 7%;
    @media screen and (max-width: 575px) {
        padding: 40px 5%;
        &.mar-neg-bottom-200 {
            margin-bottom: -100px;
        }
    }
    @media screen and (min-width: 576px) {
        padding: 60px 12%;
    }
    @media screen and (min-width: 1700px) {
        padding: 60px 22%;
    }

    p {
        margin-top: 1rem;
    }

    li {
        font-size: 0.9rem;
    }

    ul {
        @media screen and (max-width: 899px) {
            flex-direction: column;
        }
    }

    .list-item {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        width: 50%;
        @media screen and (max-width: 899px) {
            width: 100%;
        }
    }

    .list-marker {
        min-width: 21px;
        height: 21px;
        margin-top: 3px;
        border-radius: 50%;
        display: inline-block;
        border: 3px solid;
        vertical-align: middle;
        @media screen and (max-width: 575px) {
            min-width: 14px;
            height: 14px;
            margin-top: 6px;
            border: 2px solid;
        }
    }

    .list-text {
        display: inline-block;
        max-width: 80%;
        font-size: 18px;
        line-height: 30px;
        @media screen and (max-width: 575px) {
            font-size: 0.9rem;
        }
    }
}
