/* 404 */
#page-404 {
    padding: 100px 25px;

    .container {
        max-width: 900px;
    }

    img {
        max-width: 100%;
    }

    h1 {
        color: #3e90d6;
        font-weight: bold;
        font-size: 4rem;
    }

    h2 {
        margin: 25px 0;
    }

    a {
        background-color: transparent;
        color: #3e90d6;
        border: 2px solid #3e90d6;
        text-decoration: none;
        text-transform: uppercase;
        padding: 10px;
        font-size: 9pt;
        font-weight: 700;
        display: inline-flex;
    }

    @media (max-width: 768px) {
        text-align: center;
        img {
            margin-bottom: 50px;
            max-width: 300px;
        }
    }
}
