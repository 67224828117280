.doc-container {
    border-radius: 28px;
    cursor: pointer;
    box-shadow: 5px 5px 30px #efefef;
}

.doc-img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.doc-container-with-bg {
    .doc-container {
        box-shadow: initial;
    }
    .card-shadow:hover {
        box-shadow: 0 0 20px 0 #a0a0a0;
    }
}

.docs-height-2 {
    height: 755px;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: 660px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 512px;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
        height: 855px;
    }
    @media screen and (max-width: 479px) {
        height: 512px;
    }
}

.docs-height-3 {
   height: 512px;
    @media screen and (min-width: 480px) and (max-width: 767px) {
        height: 855px;
    }
}
