@font-face {
    font-family: "Metropolis-Bold";
    src: url("../fonts/Metropolis/Metropolis-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-light";
    src: url("../fonts/Metropolis/Metropolis-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-Light";
    src: url("../fonts/Metropolis/Metropolis-Light.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-ExtraLight";
    src: url("../fonts/Metropolis/Metropolis-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-Thin";
    src: url("../fonts/Metropolis/Metropolis-Thin.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-SemiBold";
    src: url("../fonts/Metropolis/Metropolis-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-ExtraBold";
    src: url("../fonts/Metropolis/Metropolis-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-Medium";
    src: url("../fonts/Metropolis/Metropolis-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Metropolis-BoldItalic";
    src: url("../fonts/Metropolis/Metropolis-BoldItalic.otf") format("opentype");
}

body {
    font-family: "Metropolis-Regular", sans-serif;
    font-size: 20px;
    line-height: 28px;
    @media screen and (max-width: 575px) {
        font-size: 0.9rem;
    }
}

small {
    font-size: 16px;
}

.big-font {
    font-size: 180px;
    line-height: 190px;
    @media screen and (max-width: 575px) {
        font-size: 100px;
        line-height: 120px;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        font-size: 160px;
        line-height: 172px;

    }
}

h1 {
    font-family: "Metropolis-Bold", sans-serif;
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    @media screen and (max-width: 575px) {
        font-size: 1.5rem;
        line-height: 40px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 2rem;
        line-height: 40px;

    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 2.5rem;
        line-height: 48px;

    }
    @media screen and (min-width: 1200px) and (max-width: 1499px) {
        font-size: 3rem;
        line-height: 64px;

    }
}

h2, .h3-to-h2 {
    font-family: "Metropolis-Bold", sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 48px;
    @media screen and (max-width: 575px) {
        font-size: 1.3rem;
        line-height: 36px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
        font-size: 1.8rem;
        line-height: 36px;

    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 2rem;
        line-height: 40px;

    }
}

h3 {
    font-family: "Metropolis-SemiBold", sans-serif;
    font-size: 28px;
    line-height: 36px;
    @media screen and (max-width: 576px) {
        font-size: 1.2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 26px;
        line-height: 32px;

    }
}

h3.gdpr-title {
    font-family: 'Metropolis-Regular', sans-serif;
}

h4 {
    font-family: "Metropolis-SemiBold", sans-serif;
    font-size: 24px;
    line-height: 30px;
    @media screen and (max-width: 576px) {
        font-size: 1.1rem;
    }
}

h5 {
    font-family: "Metropolis-SemiBold", sans-serif;
    font-size: 20px;
    line-height: 24px;
    @media screen and (max-width: 576px) {
        font-size: 1.1rem;
    }
}

.medium {
    font-family: "Metropolis-Medium", sans-serif;
    font-weight: 500;
}

.semi-bold {
    font-family: "Metropolis-SemiBold", sans-serif;
    font-weight: 600;
}

.bold {
    font-family: "Metropolis-Bold", sans-serif;
    font-weight: 700;
}

.bold-italic {
    font-family: "Metropolis-BoldItalic", sans-serif;
    font-weight: 700;
}

.extra-bold {
    font-family: "Metropolis-ExtraBold", sans-serif;
    font-weight: 800;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
    @media screen and (max-width: 576px) {
        font-size: 13px;
    }
}

.font-15 {
    font-size: 15px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px;
    @media screen and (max-width: 575px) {
        font-size: 20px;
    }
}

.font-28 {
    font-size: 28px;
}

.font-32 {
    font-size: 32px;
    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 40px;
    }
}

.font-40 {
    font-size: 40px;
    @media screen and (max-width: 575px) {
        font-size: 28px;
    }
    &.font-40-landing {
        font-size: 36px;
    }
}

.font-48 {
    font-size: 48px;
    line-height: 48px;
    @media screen and (max-width: 575px) {
        font-size: 30px;
        line-height: initial;
    }
}

.font-80 {
    font-size: 80px;
    @media screen and (max-width: 575px) {
        font-size: 80px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1699px) {
        font-size: 60px;
    }
    &.font-80-landing {
        font-size: 60px;
    }
}

.line-h-1 {
    line-height: 1;
}

.line-h-2-3 {
    line-height: 2.3;
}

.line-h-16 {
    line-height: 16px;
}

.line-h-20 {
    line-height: 20px;
}

.line-h-22 {
    line-height: 22px;
}

.line-h-28 {
    line-height: 28px;
}

.line-h-30 {
    line-height: 30px;
}

.line-h-32 {
    line-height: 32px;
}

.line-h-40 {
    line-height: 40px;
}

.line-h-48 {
    line-height: 48px;
    @media screen and (max-width: 575px) {
        line-height: 40px;
    }
}

.line-h-56 {
    line-height: 56px;
    @media screen and (max-width: 575px) {
        line-height: 46px;
    }
}

.line-h-80 {
    line-height: 80px;
    @media screen and (max-width: 575px) {
        line-height: 44px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1699px) {
        line-height: 60px;
    }
}

p,
a,
a:hover {
    color: #232323;
    text-decoration: none;
}

a.bg-blue:hover {
    color: white;
}

a:hover {
    opacity: 75%;
}

ul, ol {
    display: inline-block;
}

footer, nav {
    ul, ol {
        list-style-type: none;
        padding-left: initial;
        display: initial;
    }
}

input:focus, textarea:focus {
    border: none;
    outline: none;
}

.text-blocks {
    @media screen and (max-width: 575px) {
        padding: 0 5%;
    }
    @media screen and (min-width: 1500px) {
        padding-left: 7%;
        padding-right: 7%;
    }
}

.dropdown-title {
    font-size: 15px;
    text-transform: uppercase;
    white-space: normal;
}

strong {
    font-family: Metropolis-SemiBold,sans-serif;
}

b {
    font-weight: bold;
    font-family: "Metropolis-Bold", sans-serif;
}

ul, ol {
    display: inline-block;
    text-align: left;
}

blockquote {
    background: #f7f7f7;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.accordion-answer, .service-text-container, .card-text {
    a, a:link,  a:visited, a:active  {
        color: #70b4a2;
    }
}

p, a, li {
    @media screen and (min-width: 576px) and (max-width: 1199px) {
        font-size: 18px;
    }
}
