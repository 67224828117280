.landing-wrapper {
    .big-button {
        font-size: 18px;
        @media screen and (max-width: 576px) {
            font-size: 16px;
        }
    }
    .wrapper {
        @media screen and (min-width: 1700px) {
            padding: 0 10%;
        }
    }
}

.landing-clients {
    display: inline-block;
    margin: 15px;
    @media screen and (max-width: 394px) {
        width: 80%;
    }
}

.landing-logos {
    width: 75%;
    @media screen and (min-width: 395px) {
        max-width: 142px;
    }
    @media screen and (min-width: 1440px) and (max-width: 1700px) {
        max-width: 150px;
    }
}

.landing-clients-container {
    @media screen and (min-width: 395px) {
        a:first-child {
            margin-left: 0;
        }
        a:last-child {
            margin-right: 0;
        }
    }
}

.landing-card-img {
    width: 41px;
    height: 41px;
    object-fit: cover;
    border-radius: 100%;
}

.card {
    border: initial;
    border-radius: 32px;
}

.card-body {
    padding: 48px 32px;
}

.landing-navbar {
    padding-right: 10%;
    padding-left: 10%;
    @media screen and (max-width: 575px) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        padding-right: 3%;
        padding-left: 3%;
    }
    &.landing-short-navbar {
        @media screen and (min-width: 1200px) and (max-width: 1800px) {
            padding-right: 3%;
            padding-left: 3%;
        }
    }

}

.landing-button {
    padding: 16px 24px;
    font-size: 18px;
    line-height: 28px;
    border-radius: 32px;
    border: none;
}

.contact-hero-container p.line-h-48 {
    line-height: 48px;
    word-wrap: break-word;
    @media screen and (max-width: 575px) {
        line-height: 32px;
    }
}

.row.mb-5-rem {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-bottom: 3rem;
    }
}

.landing-big-img {
    z-index: -10;
    max-width: 100%;
    @media screen and (min-width: 992px) {
        max-width: 100.5%;
    }

}

.landing-text-container {
    z-index: 1;
    margin-right: -17%;
}

.landing-icon {
    max-width: 88px;
}

.small-landing-banner {
    max-width: 100%;
    display: block;
    position: relative;
    @media screen and (min-width: 768px) {
        height: 196px;
    }
    @media screen and (min-width: 1200px) {
        margin-top: -80px;
        left: initial;
    }
}

.landing-footer {
    padding-right: 15px;
    padding-left: 15px;
    @media screen and (min-width: 576px) {
        padding-right: 5%;
        padding-left: 5%;
    }
    @media screen and (min-width: 768px) {
        padding-right: calc(5% + 15px);
        padding-left: calc(5% + 15px);
    }
    @media screen and (min-width: 1700px) {
        padding-right: 10%;
        padding-left: 10%;
    }
    hr {
        border-top: 1px solid rgba(35, 35, 35, 0.5);
    }
}

.mr-18-per {
    @media screen and (min-width: 1200px) {
        margin-right: 18%;
    }
}

#back-to-top-icon {
    transform: rotate(180deg)
}

#back-to-top {
    position: fixed;
    bottom: 75px;
    right: 25px;
    z-index: 1000;
    display: none;
}

.cards-with-shadow {
    background: white;
    box-shadow: 0 2px 10px #dcdcdc;
}

.contact-hero-container {
    &.landing-hero-container {
        @media screen and (max-width: 575px) {
            .width-80px {
                width: 60px;
            }
            p.line-h-48 {
                line-height: 28px;
                font-size: 22px;
                padding-left: 0;
            }
        }
        @media screen and (min-width: 576px) and (max-width: 1199px) {
            p.line-h-48 {
                line-height: 32px;
                font-size: 28px;
                padding-left: 20px;
            }
        }
        @media screen and (min-width: 1200px) {
            margin-top: 90px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1499px) {
            margin-left: 3%;
            margin-right: 3%;
            p.line-h-48 {
                line-height: 36px;
                padding-left: 20px;
                font-size: 30px;
            }
        }
        @media screen and (min-width: 1499px) and (max-width: 1699px) {
            margin-left: 5%;
            margin-right: 5%;
            p.line-h-48 {
                line-height: 36px;
                padding-left: 20px;
                font-size: 30px;
            }
        }

        .line-h-80 {
            line-height: 80px;
            @media screen and (max-width: 1199px) {
                line-height: 44px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1699px) {
                line-height: 60px;
            }
        }
        .contact-form-container {
            margin-top: initial;
        }
        .input-fields {
            margin-top: 1rem !important;
        }
    }
}

.landing-spacing {
    padding-top: 6rem;
    @media screen and (max-width: 575px) {
        padding-top: 4rem;
    }
}

.landing-short-hero-container {
    @media screen and (min-width: 1200px) {
        .contact-form-container {
            margin-top: -165px;
        }
    }
    @media screen and (min-width: 1200px) and (max-width: 1800px) {
        margin-left: 3%;
        margin-right: 3%;
    }
    .input-fields {
        margin-top: 1rem !important;
    }
    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        .contact-form-container.col-xl-4 {
            ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
        }
        .contact-hero-text-container.col-xl-8 {
            -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        }
    }
    .row.mb-5-rem {
        margin-bottom: 3rem;
    }
    @media screen and (min-width: 576px) and (max-width: 1199px) {
        .contact-hero-text-container {
            h1 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

}
