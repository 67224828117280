.hero-img-container {
    overflow: hidden;
}

.hero-img {
    width: 100%;
    @media screen and (max-width: 991px) {
        width: 79%;
    }
}

.image-cut {
    margin-right: -20%;
}

li.nav-item a {
    font-size: 18px;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        font-size: 16px;
    }
}

.hero-container {
    margin: 0 0 0 10%;
    align-content: center;
    &.pad-vert-120 {
        @media screen and (min-width: 992px) {
            //padding-top: 150px;
            &.hero-flex-page {
                align-items: center;
                padding-top: 80px;
                padding-bottom: 0;
            }
        }
    }
    &.mx-10-per {
        margin-left: 10%;
        margin-right: 10%;
    }
    p, h4 {
        padding-bottom: 1rem;
        margin-bottom: 0;
    }
    .left-call {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &.mx-10-per {
        @media screen and (max-width: 1699px) {
            margin: 0 5%;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: initial;

    }
    @media screen and (max-width: 991px) {
        h1,
        p {
            margin-right: 5%;
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 3rem;
        p {
            margin-top: 0;
        }
    }
}

.input-news-email {
    border-radius: 60px;
    border: none;
    height: 68px;
    width: 360px;
    padding-left: 30px;
    padding-right: 70px;
    @media screen and (max-width: 629px) {
        width: 100%;
        height: 56px;
        padding-right: 30px;
        margin-top: 15px;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        max-width: 360px;
        width: initial;
        padding-right: 50px;

    }
}

.submit-news-email {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    width: 90%;

    &.big-button {
        @media screen and (max-width: 991px) {
            padding: 16px 44px;
            font-size: 24px;
        }
       @media screen and (min-width: 992px) and (max-width: 1200px) {
           padding: 16px 24px;
       }
        @media screen and (max-width: 629px) {
            padding: 13px;
            font-size: 20px;
            width: 100%;
        }
    }
    @media screen and (min-width: 630px) {
        margin-left: -60px;
        margin-right: initial;
        margin-top: initial;
        width: initial;
        height: 68px;
    }
}

.form-group {
    @media screen and (max-width: 629px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
.form-news {
    @media screen and (max-width: 629px) {
       // margin-right: 5%;
    }
}

@media screen and (max-width: 575px) {
    .hero-title {
        line-height: 32px;
    }
    .hero-text {
       h4 {
            line-height: 26px;
       }
       p {
           line-height: 24px;
       }
    }
}
