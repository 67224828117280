.footer {
    padding: 50px 10% 60px;

    p {
        overflow-wrap: break-word;
        margin-bottom: 0.25rem;
    }

    @media screen and (max-width: 575px) {
        padding-bottom: 0;
    }
    @media screen and (min-width: 576px) {
        padding-bottom: 0;
    }
    @media screen and (max-width: 1699px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.footer-img-container {
    display: flex;
    gap: 4%;
    @media screen and (max-width: 309px) {
        gap: 1%;
    }
}

.footer-message {
    width: 100%;
    a {
        color: #ffffff;
    }
}

.seal {
    max-height: 100px;
    max-width: 100%;

}

#Botsonic-wrapper {
    z-index: 1020 !important;
  }
