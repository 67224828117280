.testimonial {
    padding: 40px 15%;
    @media screen and (max-width: 575px) {
        padding: 2rem;
    }

    @media screen and (min-width: 576px) {
        border-radius: 35px;
    }
    p {
        line-height: 32px;
        font-family: "Metropolis-Medium", sans-serif;
        font-weight: 500;
    }
}
