.photo-gallery-container {
    @media screen and (min-width: 1200px) {
        height: 354px;
    }
    .row {
        height: 100%;
        overflow: hidden;
        @media screen and (min-width: 576px) {
            border-radius: 35px;
        }
    }
    .img-fluid {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .no-gutters {
        @media screen and (max-width: 575px) {
            margin-right: -15px;
            margin-left: -15px;
        }
    }
}

.big-social-icon {
    width: 67px;
    @media screen and (max-width: 575px) {
        width: 40px;
    }
}

.glassdoor-recommendation-icon {
    max-width: 60px;
}

.arc-bg.career-arc {
    @media screen and (max-width: 575px) {
        border-radius: initial;
    }
}
