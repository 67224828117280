.bg-light-green {
    background: #e2edea;
}

.bg-light-red {
    background: #facecc;
}

.bg-light-yellow {
    background: #f5eccf;
}

.bg-light-grey {
    background: #f7f7f7;
}

.bg-light-grey-grad {
    background: rgb(247, 247, 247);
    background: linear-gradient(
        180deg,
        rgba(247, 247, 247, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.bg-green {
    background: #abd7cd;
}

.bg-red {
    background: #e75b54;
}

.bg-yellow {
    background: #eabc35;
}

.bg-grey {
    background: #9b9b9b;
}

.bg-black {
    background: #242424;
}

.bg-white {
    background: #ffffff;
}

.bg-blue {
    background: #4267B2;
}

.white {
    color: #ffffff;
}

.black {
    color: #232323;
}

.dark-black {
    color: #242424;
}

.light-grey {
    color: rgba(35, 35, 35, 0.5);
}

.grey,
.border-grey {
    color: #727272;
}

.yellow,
.border-yellow {
    color: #eabc35;
}

.green,
.border-green {
    color: #70b4a2;
}

.red,
.border-red {
    color: #e85c54;
}

.opacity-03 {
    opacity: 0.3;
}
