body.no-scroll {
    overflow-x: hidden;
}

.lang-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1059;
    display: none;
}

#language-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1060;
    display: none;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    transition: transform 0.5s ease;

    .show {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    .lang-popup-buttons {
        display: flex;
        justify-content: center;
    }

    @media screen and (min-width: 576px) and (max-width: 1199px) {
        .lang-popup-text {
            p {
                font-size: 14px;
            }
        }
    }
}
