html {
    height: 100vh;
}

.wrapper {
    @media screen and (min-width: 576px) {
        padding: 0 5%;
    }
    @media screen and (min-width: 1700px) {
        padding: 0 18%;
    }
}

//margins

.reset-margin-x {
    margin-left: 0;
    margin-right: 0;
}

.mar-top-3 {
    margin-top: 3px;
}

.mar-top-5 {
    margin-top: 5px;
}

.mar-top-25 {
    margin-top: 25px;
}

.mar-top-35 {
    margin-top: 35px;
}

.mar-top-50 {
    margin-top: 50px;
}

.mar-top-120 {
    margin-top: 120px;
    @media screen and (max-width: 991px) {
        margin-top: 80px;
    }
}

.mar-top-360 {
    margin-top: 360px;
}

.mar-top-400 {
    margin-top: 400px;
}

.mar-bottom-120 {
    margin-bottom: 120px;
}

.mar-bottom-200 {
    margin-bottom: 200px;
}

.mb-4-rem {
    margin-bottom: 4rem;
}

.mb-5-rem {
    margin-bottom: 5rem;
}


.mar-neg-top-50 {
    margin-top: -50px;
}

.mar-neg-top-150 {
    margin-top: -150px;
}

.mar-neg-top-177 {
    margin-top: -177px;
}

.mar-neg-bottom-7 {
    margin-bottom: -7px;
}

.mar-neg-bottom-200 {
    margin-bottom: -200px;
}

.mar-neg-bottom-250 {
    margin-bottom: -250px;
}

.mt-80px {
    margin-top: 80px !important;
}

.mar-neg-top-160 {
    margin-top: -160px;
}

.mar-neg-top-400 {
    margin-top: -400px;
}

.mt-sm-2-5-rem {
    @media screen and (min-width: 576px) {
        margin-top: 2.5rem !important;
    }

}
//paddings

.reset-padding-x {
    padding-left: 0;
    padding-right: 0;
}

.pad-vert-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pad-vert-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pad-left-30 {
    padding-left: 30px;
}

.pad-left-160 {
    @media screen and (min-width: 992px) {
        padding-left: 160px !important;
    }
}

.pad-right-50 {
    padding-right: 50px;
}

.pad-right-25-per {
    padding-right: 25%;
}

.pad-hor-5-per-max-575 {
    @media screen and (max-width: 575px) {
        padding: 0 5%;
    }
}

.pad-hor-10-per {
    padding-left: 10%;
    padding-right: 10%;
}

.pad-hor-15-per {
    padding-left: 15%;
    padding-right: 15%;
}

.pad-hor-20-per {
    padding-left: 20%;
    padding-right: 20%;
}

.pad-hor-25-per {
    padding-left: 25%;
    padding-right: 25%;
}

.pad-hor-160 {
    padding-left: 160px;
    padding-right: 160px;
}
.pad-top-120 {
    padding-top: 120px;
}
.pad-top-550 {
    padding-top: 550px;
}

.pad-bot-120 {
    padding-bottom: 120px;
}

.pad-xl-150 {
    @media screen and (min-width: 1699px) {
        padding-right: 150px;
    }
}

.pad-r-0 {
    padding-right: 0;
}

.reset-position {
    position: initial;
}

.h-100vh {
    height: 100vh;
}

.w-max-content {
    width: max-content;
}

.w-xl-max-content {
    @media screen and (min-width: 1200px) {
        width: max-content;
    }
}

.max-w-100 {
    max-width: 100%;
}

.width-100 {
    width: 100%;
}

.width-80px {
    width: 80px;
}

.height-100px {
    height: 100px;
}
