.breadcrumb-container {
    position: relative;
    top: 110px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &.px-10-per {
        padding-left: 10%;
        padding-right: 10%;
    }
    &.px-10-per {
        @media screen and (max-width: 1699px) {
            padding: 0 5%;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    padding: 0;

    a, li {
        font-size: 0.6rem;
        @media screen and (min-width: 575px) {
            font-size: 0.9rem;
        }
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
        color: #232323;
    }

    a {
        color: #eabc35;
        text-decoration: none;
    }

    .breadcrumb-item.active {
        color: #232323;
    }
}
