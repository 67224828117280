.blocks-spacing-1 {
    padding-top: 3rem;
    @media screen and (max-width: 575px) {
        padding-top: 1.5rem;
    }
    @media screen and (min-width: 575px) and (max-width: 991px)  {
        padding-top: 2.25rem;
    }
}

.blocks-spacing-2 {
    padding-top: 6rem;
    @media screen and (max-width: 575px) {
        padding-top: 3rem;
    }
    @media screen and (min-width: 575px) and (max-width: 991px)  {
        padding-top: 4.5rem;
    }
}

.blocks-spacing-3 {
    padding-top: 9rem;
    @media screen and (max-width: 575px) {
        padding-top: 4.5rem;
    }
    @media screen and (min-width: 575px) and (max-width: 991px)  {
        padding-top: 6.75rem;
    }
}

.blocks-spacing-4 {
    padding-top: 12rem;
    @media screen and (max-width: 575px) {
        padding-top: 6rem;
    }
    @media screen and (min-width: 575px) and (max-width: 991px)  {
        padding-top: 9rem;
    }
}

.blocks-spacing-5 {
    padding-top: 15rem;
    @media screen and (max-width: 575px) {
        padding-top: 7.5rem;
    }
    @media screen and (min-width: 575px) and (max-width: 991px)  {
        padding-top: 11.25rem;
    }
}
