.hr-service-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 85px;
    @media screen and (min-width: 0px) and (max-width: 592px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0 5%;
    }

    &.gap-35-85 {
        gap: 35px 85px;
    }
}

.service-img {
    border-radius: 35px;
    width: 100%;
}

.service-links {
    @media screen and (min-width: 1400px) {
        padding-right: 45%;
    }
}

.service-cards {
    flex: 2 0 25%;
    max-width: 29%;
    min-width: 300px;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 5px 5px 30px #efefef;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 20px 0 #a0a0a0;
    }
    @media screen and (min-width: 0px) and (max-width: 299px) {
        min-width: initial;
    }
    @media screen and (min-width: 0px) and (max-width: 761px) {
        max-width: initial;
    }
    @media screen and (min-width: 762px) and (max-width: 1188px) {
        max-width: 45%;
    }
}
